<template>
  <div>
    <div class="footer-sub-menu rounded-pill">
      <b-row
        class="justify-content-between text-center no-gutters h-100 mt-1 px-2"
      >
        <b-col cols="2 " v-for="(item, index) in navList" :key="index">
          <div
            v-if="
              item.tabIndex !== 0 && item.tabIndex !== 3 && item.tabIndex !== 4  && item.tabIndex !== 2
            "
          >
            <a :href="item.path" target="_blank">
              <img
                class="icon-w"
                :src="require(`../assets/images/Icon/${item.icon}`)"
              />

              <div class="text-menu mt-1">{{ item.text }}</div>
            </a>
          </div>
          <div v-else-if="item.tabIndex === 4">
            <a :href="item.path">
              <img
                class="icon-w"
                :src="require(`../assets/images/Icon/${item.icon}`)"
              />

              <div class="text-menu mt-1">{{ item.text }}</div>
            </a>
          </div>
          <div v-else>
            <a @click="openFamilyClub(item.path)">
              <img
                class="icon-w"
                :src="require(`../assets/images/Icon/${item.icon}`)"
              />

              <div class="text-menu mt-1">{{ item.text }}</div>
            </a>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    lineId: {
      required: false,
      type: String
    }
  },
  data: () => {
    return {
      navList: [],
      family_club: ""
    };
  },

  created() {
    this.navList = [
      {
        text: "แฟมิลี่คลับ",
        tabIndex: 0,
        icon: "Family Club.png",
        path: "loginfamilyclub"
      },
      {
        text: "ขอสินเชื่อออนไลน์",
        tabIndex: 1,
        icon: "Loan Credit.png",
        path: "https://www.skl.co.th/calculator"
      },
      {
        text: "My SKL",
        tabIndex: 2,
        icon: "My SKL.png",
        path: "loginmyskl"
      },
      {
        text: "บัญชีครัวเรือน",
        tabIndex: 3,
        icon: "Account Book.png",
        path: "loginhousehold"
      },
      {
        text: "ตั้งค่า",
        tabIndex: 4,
        icon: "Setting.png",
        path: "/setting"
      }
    ];
  },
  watch: {},
  methods: {
    async openFamilyClub(path) {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      this.$isLoadingAlpha = true;
      await fetch(
        `${this.$API_DEV_LINE_SKL}/customer/${path}/${this.lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.family_club = result.detail.url;
            window.open(this.family_club, "_blank");
          }
        })
        .catch(error => console.log("error", error));
      this.$isLoadingAlpha = false;
    },
    async openHouseHold() {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      this.$isLoadingAlpha = true;
      await fetch(
        `${this.$API_DEV_LINE_SKL}/customer/loginhousehold/${this.lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.family_club = result.detail.url;
            window.open(this.family_club, "_blank");
          }
        })
        .catch(error => console.log("error", error));
      this.$isLoadingAlpha = false;
    }
  }
};
</script>

<style scoped>
.footer-sub-menu {
  border-top: 0px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 95%;
  height: 60px !important;

  max-height: 80px;
  margin: 0 auto 16px;
  background: #f8faf9 !important;

  text-align: center;
}

.icon-w {
  width: 20px;
}

.text-menu {
  line-height: 1;
  font-size: 12px;
  text-decoration: none;
  color: black;
}

@media (max-width: 576px) {
  .text-menu {
    font-size: 10px;
  }
}
</style>
