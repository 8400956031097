<template>
  <div :class="['form-group', classFormGroup]">
    <label :for="id" v-if="textFloat">
      {{ textFloat }}
      <font color="red" v-if="required">*</font>
    </label>
    <b-input-group
      :prepend="prefix + ' - '"
      :class="[
        'input-group-prepend-custom',
        { display: prefix },
        { 'error-input': isError }
      ]"
    >
      <input
        v-if="renderComponent"
        :id="id"
        :class="[
          'form-control',
          'input-text',
          { 'error-input': isError },
          { 'input-has-prefix': prefix },
          { 'color-placeholder': changeColorPlaceholder }
        ]"
        :type="type"
        :value="value"
        @input="inputChange($event)"
        @keypress="onlyNumber"
        :placeholder="placeholder"
        :pattern="pattern"
        :maxlength="maxlength"
        :disabled="isDisabled"
        ref="formInput"
        :inputmode="inputmode"
        :formatter="maxlengthHandler"
      />
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: "input-textbox",
  props: {
    id: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    isError: {
      required: false,
      type: Boolean
    },
    textFloat: {
      required: false,
      type: String
    },
    value: {
      required: true,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },
    pattern: {
      required: false,
      type: String
    },
    prefix: {
      required: false,
      type: String
    },
    isDisabled: {
      required: false,
      type: Boolean
    },
    type: {
      required: false,
      type: String,
      default: "text"
    },
    inputmode: {
      required: false,
      type: String,
      default: "text"
    },
    inputChecker: {
      required: false,
      type: String,
      default: null
    },
    classFormGroup: {
      required: false,
      type: String,
      default: ""
    },
    maxlength: {
      required: false,
      type: Number,
      default: 524288
    },
    format: {
      required: false,
      type: String,
      default: null
    },
    changeColorPlaceholder: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      oldData: "",
      renderComponent: true
    };
  },
  created() {
    this.oldData = this.value == undefined ? "" : this.value;
  },
  computed: {
    checker: {
      get: function () {
        return this.oldData;
      },
      set: function (newValue) {
        var currentCarpetPosition = this.$refs.formInput.selectionStart;
        var moveCarpet = false;
        if (this.inputChecker == "currency") {
          newValue = newValue.replace(/^0+/, "");
          if (newValue.indexOf(".") < 0) {
            // add .00
            newValue = newValue + ".00";
            moveCarpet = true;
          } else if (
            newValue.indexOf(".") < currentCarpetPosition &&
            newValue.indexOf(".00") > -1
          ) {
            // if insert dot delete .00
            newValue = newValue.slice(0, currentCarpetPosition);
          }
          if (newValue == 0) {
            newValue = "";
          }
        }
        this.oldData = newValue;
        if (moveCarpet) {
          this.$nextTick(() => {
            this.$refs.formInput.setSelectionRange(
              currentCarpetPosition,
              currentCarpetPosition
            );
          });
        }
        this.$emit("onDataChange", this.oldData);
      }
    }
  },
  methods: {
    forceRerender(currentCarpetPosition) {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
        this.$nextTick(() => {
          this.$refs.formInput.focus();
          this.$nextTick(() => {
            this.$refs.formInput.setSelectionRange(
              currentCarpetPosition,
              currentCarpetPosition
            );
          });
        });
      });
    },
    onlyNumber($event) {
      if (this.inputChecker == "number") {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57) {
          // 46 is dot
          $event.preventDefault();
        }
      } else if (this.inputChecker == "currency") {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          // 46 is dot
          $event.preventDefault();
        }
        if (keyCode == 48 && this.$refs.formInput.selectionStart == 0) {
          $event.preventDefault();
        }
        if (
          keyCode == 46 &&
          this.oldData.indexOf(".") > -1 &&
          this.oldData.indexOf(".00") < 0
        ) {
          // handle add dot
          $event.preventDefault();
        }
        if (
          this.$refs.formInput.selectionStart > this.oldData.indexOf(".") &&
          this.oldData.indexOf(".") > -1
        ) {
          // if typing behide dot can not more than 2 digit
          if (this.oldData.split(".")[1].length >= 2) {
            $event.preventDefault();
          }
        }
      } else if (this.inputChecker === "price") {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (
          (keyCode < 48 || keyCode > 57) &&
          keyCode !== 46 &&
          keyCode !== 44
        ) {
          // 46 is dot
          $event.preventDefault();
        }
        if (keyCode == 48 && this.$refs.formInput.selectionStart == 0) {
          $event.preventDefault();
        }
      }
    },
    maxlengthHandler(e) {
      return String(e).substring(0, this.maxlength);
    },
    inputChange(e) {
      var newValue = e.target.value;
      console.log("inputChange", newValue);
      var currentCarpetPosition = this.$refs.formInput.selectionStart;
      var moveCarpet = false;
      if (this.inputChecker == "currency") {
        newValue = newValue.replace(/^0+/, "");
        if (newValue.indexOf(".") < 0) {
          // add .00
          newValue = newValue + ".00";
          moveCarpet = true;
        } else if (
          newValue.indexOf(".") < currentCarpetPosition &&
          newValue.indexOf(".00") > -1
        ) {
          // if insert dot delete .00
          newValue = newValue.slice(0, currentCarpetPosition);
        }
        if (newValue == 0) {
          newValue = "";
        }
      }
      this.oldData = newValue;
      if (moveCarpet) {
        setTimeout(() => {
          this.$refs.formInput.setSelectionRange(
            currentCarpetPosition,
            currentCarpetPosition
          );
        }, 0);
      }
      this.$emit("onDataChange", this.oldData);
      this.$emit("input", this.oldData);
    }
  }
};
</script>

<style scoped>
.input-group-prepend-custom .input-group-prepend .input-group-text {
  background-color: white !important;
  border-radius: 8px 0px 0px 8px !important;
  color: #afafaf !important;
  border-right: 0 !important;
  display: none;
  align-items: center !important;
  padding-right: 0 !important;
}
.input-group-prepend-custom.error-input .input-group-prepend .input-group-text {
  border-color: red !important;
}
.input-group-prepend-custom.display .input-group-prepend .input-group-text {
  display: flex !important;
}
.input-group-prepend-custom .input-has-prefix {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0 !important;
  padding-left: 5px !important;
  width: 74% !important;
}
.input-text:disabled {
  opacity: 0.5;
}
.color-placeholder::placeholder {
  color: #f47306 !important;
  opacity: 1; /* Firefox */
}

.color-placeholder:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #f47306 !important;
}

.color-placeholder::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #f47306 !important;
}
.input-text {
  border-radius: 8px !important;
}
</style>
