<template>
  <div class="d-flex justify-content-center custom-width">
    <div>
      <div class="main-container p-4 mt-4" ref="body">
        <div
          id="content-policy"
          class="d-flex align-items-center justify-content-between flex-column"
          ref="policyContent"
        >
          <img
            src="../assets/images/SKL/skl-logo.jpg"
            alt="Smiley face"
            width="150"
          />
          <div id="header" class="font-weight-bold" ref="header">
            ข้อกำหนดและเงื่อนไขการใช้บริการ
          </div>
          <div id="policy"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    // this.$liff
    //   .init({ liffId: this.$liff_ID_Policy })
    //   .then(() => {
    //     if (!this.$liff.isLoggedIn()) {
    //       this.$liff.login().then(() => {
    //         this.$liff.getProfile().then(profile => {
    //           // this.lineProfile = profile;
    //         });
    //       });
    //     } else {
    //       this.$liff.getProfile().then(profile => {
    //         // this.lineProfile = profile;
    //       });
    //     }
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
  },
  data: () => ({}),

  mounted() {
    document.body.classList.add("bg-body");
    this.checkConsentRegister();
  },
  methods: {
    checkConsentRegister() {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/Customer/TermsAndConditions`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            document.getElementById("policy").innerHTML = result.detail.content;
          }
        })
        .catch(error => console.log("error", error));
    }
  }
};
</script>

<style scoped>
.bg-body {
  background-color: white !important;
}


/* .sub-container {
  height: calc(100vh - 100px);
} */

.main-container {
  /* height: calc(100vh - 100px); */
  background-color: white !important;
  width: calc(100vw + 10px);
  border: 5px #7fc241 solid;
  border-bottom: none;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}



::v-deep .custom-select {
  padding: 0.375rem 0.75rem 0.375rem 0.75rem !important;
}
</style>
