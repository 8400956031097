import Vue from "vue";
import VueRouter from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";


const Home = () => import("../views/Home.vue");
const Register = () => import("../views/Register/Register.vue");
const ReportCalculator = () => import("../views/Reports/ReportCalculator.vue");
const ReportEtax = () => import("../views/Reports/ReportEtax.vue");
const Setting = () => import("../views/Setting.vue");
const DisconnectAccount = () => import("../views/DisconnectAccount.vue");
const SendDocument = () => import("../views/SendDocument.vue");
const Payment = () => import("../views/Payment.vue");
const Barcode = () => import("../views/Barcode.vue");
const Detail = () => import("../views/Detail.vue");
const Promotion = () => import("../views/Redirect.vue");
const Redirect = () => import("../views/Redirect.vue");
const Broadcast = () => import("../views/Broadcast.vue");

//Phase2
const BranchSearch = () => import("../views/BranchList.vue");
const Map = () => import("../views/Map.vue");
const FeedBack = () => import("../views/FeedBack.vue");
const RentalRequestStatus = () =>
  import("../views/RentalRequestStatus/RentalRequestStatus.vue");
const InstallmentList = () => import("../views/List/ListInstallment.vue");
const InstallmentHistory = () => import("../views/List/components/InstallmentHistory.vue");
const SKLFamily = () => import("../views/SKLFamily.vue");
const Buzzebees = () => import("../views/Buzzebees.vue");
const Point = () => import("../views/Point.vue");
const Tracking = () => import("../views/Tracking.vue");
const Policy = () => import("../views/Policy.vue");
const ChangeLine = () => import("../views/HowtoChangeLine.vue");
Vue.use(VueRouter);


export const registerComponents = app => {

  app.component("font-awesome-icon", FontAwesomeIcon);
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "SKL Connect - Home"
    }
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      title: "SKL Connect - Home"
    }
  },
  {
    path: "/report-calculator",
    name: "Calculator",
    component: ReportCalculator,
    meta: {
      title: "SKL Connect - ขอสินเชื่อออนไลน์"
    }
  },

  {
    path: "/report-etax",
    name: "E-tax",
    component: ReportEtax,
    meta: {
      title: "SKL Connect - ขอรับเอกสารอิเล็กทรอนิกส์"
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "SKL Connect - ลงทะเบียน"
    }
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    meta: {
      title: "SKL Connect - ชำระเงินออนไลน์"
    }
  },
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
    meta: {
      title: "SKL Connect - การตั้งค่า/จัดการข้อมูล"
    }
  },
  {
    path: "/disconnect-account",
    name: "DisconnectAccount",
    component: DisconnectAccount,
    meta: {
      title: "SKL Connect - ยกเลิกการลงทะเบียน"
    }
  },
  {
    path: "/send-document",
    name: "SendDocument",
    component: SendDocument,
    meta: {
      title: "SKL Connect - แนบข้อมูลเอกสาร"
    }
  },
  {
    path: "/barcode",
    name: "Barcode",
    component: Barcode,
    meta: {
      title: "SKL Connect - บาร์โคด/QR Code"
    }
  },
  {
    path: "/detail",
    name: "Detail",
    component: Detail,
    meta: {
      title: "รายละเอียด"
    }
  },
  {
    path: "/promotion",
    name: "Promotion",
    component: Promotion,
    meta: {
      title: "SKL Connect - โปรโมชั่น"
    }
  },

  //Phase 2
  {
    path: "/branch-search",
    name: "BranchSearch",
    component: BranchSearch,
    meta: {
      title: "SKL Connect - ค้นหาสาขาใกล้คุณ"
    }
  },
  {
    path: "/map",
    name: "Map",
    component: Map,
    meta: {
      title: "SKL Connect - ที่ตั้งสาขา"
    }
  },
  {
    path: "/feedback",
    name: "FeedBack",
    component: FeedBack,
    meta: {
      title: "SKL Connect - แนะนำติชมบริการ"
    }
  },
  {
    path: "/rentalRequestStatus",
    name: "RentalRequestStatus",
    component: RentalRequestStatus,
    meta: {
      title: "SKL Connect - ตรวจสอบสถานะคำขอเช่าซื้อออนไลน์"
    }
  },
  {
    path: "/redirect",
    name: "Redirect",
    component: Redirect,
    meta: {
      title: "Redirect"
    }
  },
  {
    path: "/installment/:id",
    name: "InstallmentContract",
    component: InstallmentList,
    meta: {
      title: "SKL Connect - ข้อมูลสัญญา",
      id: 0
    }
  },
  {
    path: "/installment/:id",
    name: "InstallmentDetail",
    component: InstallmentList,
    meta: {
      title: "SKL Connect - ข้อมูลค่างวด",
      id: 1
    }
  },
  {
    path: "/installment/:id",
    name: "InstallmentFamilyClub",
    component: InstallmentList,
    meta: {
      title: "SKL Connect - แฟมิลี่คลับ",
      id: 2
    }
  },
  {
    path: "/installment/:id",
    name: "PaymentHistory",
    component: InstallmentList,
    meta: {
      title: "SKL Connect - ประวัติชำระ",
      id: 3
    }
  },
  {
    path: "/installment/:id",
    name: "installmentList",
    component: InstallmentList,
    meta: {
      title: "SKL Connect - ตารางค่างวด",
      id: 4
    }
  },
  {
    path: "/installment/:id",
    name: "Invoice",
    component: InstallmentList,
    meta: {
      title: "SKL Connect - ใบแจ้งชำระ",
      id: 5
    }
  },
  {
    path: "/paymentHistory",
    name: "PaymentHistory",
    component: InstallmentHistory,
    meta: {
      title: "SKL Connect - ประวัติการชำระ"
    }
  },
  {
    path: "/track",
    name: "tracking",
    component: Tracking,
    meta: {
      title: "SKL Connect - สยามคูโบต้า ลีสซิ่ง (SKL)"
    }
  },
  {
    path: "/point",
    name: "point",
    component: Point,
    meta: {
      title: "SKL Connect - สยามคูโบต้า ลีสซิ่ง (SKL)"
    }
  },
  {
    path: "/point/:LineId",
    name: "point",
    component: Point,
    meta: {
      title: "SKL Connect - สยามคูโบต้า ลีสซิ่ง (SKL)"
    }
  },
  {
    path: "/SKLFamily",
    name: "sklFamily",
    component: SKLFamily,
    meta: {
      title: "SKL Connect - สยามคูโบต้า ลีสซิ่ง (SKL)"
    }
  },
  {
    path: "/Buzzebees",
    name: "Buzzebees",
    component: Buzzebees,
    meta: {
      title: "SKL Connect - คูปองส่วนลด"

    }
  },
  {
    path: "/policy",
    name: "policy",
    component: Policy,
    meta: {
      title: "SKL Connect - ข้อกำหนดและเงื่อนไข"
    }
  },
  {
    path: "/howto-changeline",
    name: "change line",
    component: ChangeLine,
    meta: {
      title: "SKL Connect - วิธีเปลี่ยนบัญชีไลน์ในการใช้บริการ"
    }
  },
  {
    path: "/broadcast",
    name: "broadcast",
    component: Broadcast,
    meta: {
      title: "SKL Connect - ข้อกำหนดและเงื่อนไข"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
