<template>
  <div>
    <div class="d-flex justify-content-center mt-2">
      <div class="text-center">
        <font-awesome-icon
          :icon="['fas', 'circle-exclamation']"
          class="alert-icon f-orange"
        />
        <div class="f-14 f-orange mt-1 font-weight-bold">
          ระบบพบว่าท่านเคยสมัครบริการ SKL Connect ไว้แล้ว
        </div>
        <div class="font-weight-bold f-14 mt-4">
          ท่านยืนยันสมัครบริการใหม่โดยใช้ LINE ID
          ใหม่จากโทรศัพท์มือถือเครื่องนี้ใช่หรือไม่?
        </div>

        <div class="f-14 mt-4">
          การสมัครใหม่จะทำให้ SKL Connect
          ในโทรศัพท์มือถือเครื่องก่อนหน้านี้ไม่สามารถใช้บริการได้อีกต่อไป
        </div>

        <div class="button-footer mt-4 px-2 d-flex">
          <b-button class="btn-white w-50 mr-2" @click="handleSubmit(0)">
            ยกเลิก
          </b-button>
          <b-button class="btn-white w-50" @click="handleSubmit(1)">
            ยืนยัน
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    displayName: {
      required: false,
      type: String
    }
  },
  methods: {
    handleSubmit(boolean) {
      this.$emit("handleChangeLine", boolean);
    }
  }
};
</script>
<style scoped>
.f-16 {
  font-size: 16px !important;
}

.alert-icon {
  width: 60px;
  height: 60px;
}
</style>
