<template>
  <div></div>
</template>
<script>
export default {
  data: () => ({
    lineProfile: {}
  }),

  beforeCreate() {
    document.body.classList.add("bg-body");
    let liffId = this.$liff_ID_ReportEtax;
    this.$liff
      .init({ liffId: liffId })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.sendReport();
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;

            this.sendReport();
          });
        }
        this.$liff.getFriendship().then(data => {
          if (!data.friendFlag) {
            this.$liff.openWindow({
              url: `https://line.me/R/ti/p/${this.$liff_Channel_ID}`,
              external: false
            });
            this.$liff.closeWindow();
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    async sendReport() {
      if (!this.$route.query.linerichid) {
        window.open("https://skl.co.th/myskl/e-tax", "_self");
        return;
      }
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        LineId: this.lineProfile.userId,
        LineRichId: this.$route.query.linerichid || "0",
        LineRichActionId: this.$route.query.linerichactiontid || "0",
        Sort: this.$route.query.sort || "0"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/ReportRichMenu`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            window.open("https://skl.co.th/myskl/e-tax", "_self");
          }
        });
    }
  }
};
</script>
