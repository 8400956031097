import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faCalculator,
    faTicket,
    faGift,
    faBook,
    faGear,
    faDownload,
    faCircleExclamation,
    faEye,
    faEyeSlash

} from "@fortawesome/free-solid-svg-icons";
import { faLine } from "@fortawesome/free-brands-svg-icons";

library.add(
    faCalculator,
    faTicket,
    faGift,
    faBook,
    faGear,
    faDownload,
    faCircleExclamation,
    faEye,
    faEyeSlash

);
