<template>
  <div class="">
    <div id="header">
      <div class="d-flex flex-row justify-content-around logo">
        <img
          src="../assets/images/SKL/skl-logo.jpg"
          alt="Smiley face"
          width="150"
        />
      </div>
      <div
        id="header-title"
        class="d-flex flex-row justify-content-around title"
      >
        <!-- <b>{{ header }}</b> -->
      </div>
    </div>
    <div class="body">
      <!-- <template v-if="state == 'menu'">
        <div class="" @click="state = 'fullContrctNumber'">
          <div
            class="d-flex justify-content-between border-bottom font-weight-bold pb-3 mb-3"
          >
            <span class="f-14">จัดการข้อมูลสินเชื่อ</span>
            <span class="f-orange">
              <b-icon icon="chevron-right"></b-icon>
            </span>
          </div>
        </div>
      </template> -->
      <div class="">
        <div class="d-flex align-items-center ml-3 mb-1">
          <img class="icon-w" src="../assets//images/NavFooter/settings.png" />
          <p class="f-14 f-gray font-weight-bold ml-1">การตั้งค่าทั่วไป</p>
        </div>

        <div
          class="d-flex justify-content-between align-items-center border-bottom border-top py-2 px-3"
        >
          <div class="ml-1">
            <p class="f-14 mb-0 f-medium">แสดงเลขสัญญา</p>
            <p class="f-small-gray mb-0">
              {{
                fullContrctNumber
                  ? "ตัวอย่าง 1234567890"
                  : "ตัวอย่าง XXXXXX7890"
              }}
            </p>
          </div>
          <b-form-checkbox
            switch
            size="lg"
            class="radio-active"
            v-model="settings.contractTenDigit"
            value="1"
            unchecked-value="0"
            :disabled="buttonLoading"
          ></b-form-checkbox>
        </div>
        <div class="d-flex align-items-center ml-3 my-2">
          <img class="icon-w" src="../assets//images/NavFooter/term.png" />
          <p class="f-14 f-gray font-weight-bold ml-1">
            ข้อกำหนดและความเป็นส่วนตัว
          </p>
        </div>

        <div v-for="(policy, index) in policyMenu" :key="`policy-${index}`">
          <router-link v-if="index == 0" target="_blank" :to="policy.path">
            <div
              class="d-flex justify-content-between align-items-center border-top py-2 px-3"
            >
              <p class="f-14 mb-0 ml-1 f-medium">{{ policy.text }}</p>
              <span class="f-orange">
                <b-icon icon="chevron-right"></b-icon>
              </span>
            </div>
          </router-link>
          <div
            v-else
            class="d-flex justify-content-between align-items-center py-2 border-top px-3"
            @click="goTo(policy.path)"
            :class="{ 'border-bottom': index == 2 }"
          >
            <p class="f-14 mb-0 ml-1 f-medium">{{ policy.text }}</p>
            <span class="f-orange">
              <b-icon icon="chevron-right"></b-icon>
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center ml-3 my-2">
          <img
            class="icon-w"
            src="../assets//images/NavFooter/change-line.png"
          />
          <p class="f-14 f-gray font-weight-bold ml-1">
            การเปลี่ยนบัญชีไลน์ในการใช้บริการ
          </p>
        </div>

        <router-link target="_blank" to="/howto-changeline">
          <div
            class="d-flex justify-content-between align-items-center border-bottom border-top py-2 px-3"
          >
            <p class="f-14 ml-1 mb-0 f-medium">
              วิธีเปลี่ยนบัญชีไลน์ในการใช้บริการ
            </p>
            <span class="f-orange">
              <b-icon icon="chevron-right"></b-icon>
            </span>
          </div>
        </router-link>
        <router-link target="_blank" to="/disconnect-account">
          <div
            class="d-flex justify-content-between align-items-center border-bottom border-top py-2 px-3 mt-5"
          >
            <div class="d-flex align-items-center">
              <img class="icon-w" src="../assets/images/NavFooter/cancel.png" />
              <p class="f-14 font-weight-bold ml-1">
                ยกเลิกลงทะเบียนใช้บริการ SKL Connect
              </p>
            </div>
            <!-- <p class="f-14 mb-0 font-weight-bold">
              ยกเลิกลงทะเบียนการใช้บริการ SKL Connect
            </p> -->

            <span class="f-orange">
              <b-icon icon="chevron-right"></b-icon>
            </span>
          </div>
        </router-link>
        <!-- <div class="d-flex justify-content-between border-bottom pb-3 mb-3">
          <div>
            <p class="f-14 mb-0 font-weight-bold">แสดงเลขสัญญา</p>
            <p class="f-small-gray mb-0">แสดงเลขสัญญา</p>
          </div>
          <b-form-checkbox switch size="lg" class="radio-active" v-model="checked"></b-form-checkbox>
        </div>
        <div class="d-flex justify-content-between border-bottom pb-3 mb-3">
          <div>
            <p class="f-14 mb-0 font-weight-bold">แสดงเลขสัญญา</p>
            <p class="f-small-gray mb-0">แสดงเลขสัญญา</p>
          </div>
          <b-form-checkbox switch size="lg" class="radio-active" v-model="checked"></b-form-checkbox>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router";

export default {
  data() {
    return {
      header: "จัดการข้อมูลสินเชื่อ",
      fullContrctNumber: false,
      state: "fullContrctNumber",
      settings: {
        contractTenDigit: "0",
        lineId: ""
      },
      lineProfile: {},
      buttonLoading: false,
      policyMenu: [
        { text: "ข้อกำหนดและเงื่อนไขบริการ", path: "/policy" },
        {
          text: "นโยบายคุ้มครองข้อมูลส่วนบุคคล",
          path: "https://www.skl.co.th/privacy_policy"
        },
        {
          text: "นโยบายการใช้คุกกี้",
          path: "https://www.skl.co.th/cookies_policy"
        }
      ]
    };
  },
  watch: {
    settings: {
      deep: true,
      handler: function () {
        this.buttonLoading = true;
        var myHeaders = new Headers();
        myHeaders.append("API-KEY", "S1112LIN05!");
        myHeaders.append("Content-Type", "application/json");

        var sendData = this.settings;
        sendData["lineId"] = this.lineProfile.userId;

        var raw = JSON.stringify(sendData);

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        fetch(`${this.$API_DEV_LINE_SKL}/CustomerSetting`, requestOptions)
          .then(response => response.json())
          .then(result => {
            this.buttonLoading = false;
          })
          .catch(error => {
            console.error(error);
            this.buttonLoading = false;
            this.$bvModal.show("modal-tryagain");
          });
      }
    }
  },
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_Setting })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.getLineProfile();
          });
        } else {
          this.getLineProfile();
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.push({
              path: "/policy",
              query: {
                lineid: lineId,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getUserSetting() {
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", "S1112LIN05!");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/CustomerSetting/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.settings = result.detail.customer;
          }
        })
        .catch(error => {
          this.$bvModal.show("modal-tryagain");
          console.error(error);
        });
    },
    getLineProfile() {
      this.$liff.getProfile().then(profile => {
        this.lineProfile = profile;
        this.getUserSetting();
        this.checkTerm(profile.userId);
      });
    },
    goTo(path) {
      window.open(path, "_blank");
    }
  }
};
</script>

<style scoped>
::v-deep
  .radio-active
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #80c141;
  background-color: #80c141;
}
::v-deep .radio-active.custom-switch.b-custom-control-lg .custom-control-label,
.input-group-lg .custom-switch .custom-control-label {
  font-size: 1rem;
  line-height: 1.75rem;
  padding-left: 1rem;
}
::v-deep .radio-active .custom-control-label::before {
  top: 0rem !important;
  height: 1.75rem !important;
  left: -2.15rem !important;
  width: 3rem !important;
  border-radius: 60px !important;
}
::v-deep .radio-active.custom-switch .custom-control-label::after {
  cursor: pointer;
  top: calc(0.05rem + 2px);
  left: calc(-2rem + 2px);
  width: calc(1.65rem - 4px);
  height: calc(1.65rem - 4px);
  border-radius: 35rem;
  background-size: 50% 50%;
}
::v-deep
  .radio-active
  .custom-control-input:checked
  ~ .custom-control-label::after {
  left: calc(-1.75rem + 2px);
}
::v-deep
  .radio-active
  .custom-control-input:focus
  ~ .custom-control-label::before {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border-color: #adb5bd;
}

a {
  color: black !important;
  text-decoration: none !important;
}
a:hover {
  color: black !important;
  text-decoration: none !important;
}

.icon-w {
  width: 30px;
  height: 30px;
}
</style>
