<template>
  <div class="main-container m-auto">
    <div id="header">
      <div class="d-flex flex-row justify-content-around logo">
        <img
          src="../assets/images/SKL/skl-logo.jpg"
          alt="Smiley face"
          width="150"
        />
      </div>
      <div id="header-title" class="text-center title">
        <b>ยกเลิกการลงทะเบียนใช้บริการ</b>
        <br />
        <b>SKL Connect</b>
      </div>
    </div>
    <div class="body text-center">
      <div
        v-if="page == 1"
        class="d-flex align-items-center body-h justify-content-center"
      >
        <div>
          <b class="f-red">ต้องการยกเลิกบริการ</b>
          <br />
          <b class="f-red">SKL Connect ใช่หรือไม่ ?</b>
          <br />
          <br />
          <p class="f-14 font-weight-bold">
            คุณจะไม่สามารถรับข้อมูล ตรวจสอบข้อมูล<br />
            และรับการแจ้งเตือนผ่านช่องทางไลน์ได้อีก
          </p>
          <p class="f-14 f-gray mb-0">
            ระบบจะส่งหมายเลข OTP ไปยังเบอร์โทรศัพท์
          </p>
          <p class="f-14 f-gray">
            {{ customerProfile.mobileNumber.slice(0, 3) }}-XXX-{{
              customerProfile.mobileNumber.slice(6)
            }}
          </p>
          <br />
          <br />
          <br />
          <div class="form-inline">
            <div class="w-50 pr-1">
              <b-button
                class="w-100 m-0 custom-btn"
                variant="white"
                @click="confirmDisconnect"
                >ใช่</b-button
              >
            </div>
            <div class="w-50 pl-1">
              <b-button
                class="w-100 custom-btn"
                variant="submit"
                @click="closeWindow"
                >ไม่ใช่</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="page == 2">
        <FormOTP
          id="put-OTP"
          v-model="form.otpNumber"
          placeholder="ํOTP"
          type="number"
          pattern="\d*"
          inputmode="numeric"
          :isError="otpState.isError"
          :MobileNumber="customerProfile.mobileNumber"
          :prefix="otpState.prefix"
          @sendOTPAgain="sendOTP"
        />
        <!-- v-model="form.optNumber"
        @onDataChange="(val) => form.optNumber = val"-->
        <br />
        <br />
        <br />
        <div class="text-center">
          <p class="f-14 f-red px-5" v-if="otpState.isError">
            ขออภัย ท่านกรอกรหัสผ่าน OTP ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง
          </p>
          <b-button
            :disabled="otpState.waiting"
            @click="submitOTP"
            id="submit-OTP"
            variant="submit"
            class="btn-submit w-100 mt-4"
          >
            <b-spinner small v-if="buttonLoading"></b-spinner>
            <span v-else>ยืนยัน</span>
          </b-button>
        </div>
      </div>
      <div v-else-if="page == 3">
        <PageResult :data="resultData" />
      </div>
    </div>
  </div>
</template>

<script>
const FormOTP = () => import("@/components/panels/formOTP");
const PageResult = () => import("@/components/panels/PageResult");

export default {
  components: {
    FormOTP,
    PageResult
  },
  data() {
    return {
      lineProfile: {},
      buttonLoading: false,
      customerProfile: {
        mobileNumber: "08xxxxxxxx"
      },
      checked: false,
      page: 1,
      otpState: {
        isError: false,
        prefix: "xxxx"
      },
      form: {
        otpNumber: ""
      },
      resultData: {
        status: "success",
        msg: "ยกเลิกการลงทะเบีบยนใช้บริการ SKL Connect สำเร็จ"
        // status: 'failed',
        // msg: 'ยกเลิกการลงทะเบียนใช้บริการ SKL Connect ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง'
      }
    };
  },
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_Disconnect })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.getLineProfile();
          });
        } else {
          this.getLineProfile();
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.push({
              path: "/policy",
              query: {
                lineid: lineId,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getLineProfile() {
      this.$liff.getProfile().then(profile => {
        this.lineProfile = profile;
        this.checkTerm(profile.userId);
        var requestOptions = {
          method: "GET",
          redirect: "follow"
        };
        fetch(
          `${this.$API_DEV_LINE_SKL}/Customer/` + this.lineProfile.userId,
          requestOptions
        )
          .then(response => response.json())
          .then(result => {
            // this.customerProfile = {
            //   mobileNumber: "0873002662"
            // };
            this.customerProfile = result.detail.customer;
            if (this.$OverrideMobileNumber) {
              this.customerProfile.mobileNumber = this.$OverrideMobileNumber;
            }
          })
          .catch(error => {
            console.error(error);
          });
      });
    },
    closeWindow() {
      this.$liff.closeWindow();
    },
    confirmDisconnect() {
      this.page = 2;
      this.sendOTP();
    },
    sendOTP() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        MobileNumber: `66${this.customerProfile.mobileNumber.slice(1)}`,
        LineId: this.lineProfile.userId,
        IdCard: this.customerProfile.idCard
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/OTPDisconnect`, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.otpState.prefix = result.detail.result;
        })
        .catch(error => {
          console.error(error);
        });
    },
    submitOTP() {
      this.buttonLoading = true;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        MobileNumber: `66${this.customerProfile.mobileNumber.slice(1)}`,
        OTP: this.form.otpNumber,
        IdCard: this.customerProfile.idCard,
        LineId: this.lineProfile.userId
      });

      // console.log(raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/ValidateOTPUnregister`, requestOptions)
        .then(response => response.json())
        .then(result => {
          // console.log(result);
          this.buttonLoading = false;
          if (result.result == 1) {
            this.otpState.isError = false;
            this.disconnetAccount(true);
          }
          if (result.result == 0) {
            this.otpState.isError = true;
          }
          if (result.result == 2) {
            this.otpState.isError = false;
            this.disconnetAccount(false);
          }
        })
        .catch(error => {
          this.$bvModal.show("modal-tryagain");
          this.buttonLoading = false;
          console.error(error);
        });
    },
    disconnetAccount(result) {
      this.page = 3;
      if (result) {
        this.resultData = {
          status: "success",
          msg: "ยกเลิกการลงทะเบียนใช้บริการ SKL Connect สำเร็จ"
        };
      } else {
        this.resultData = {
          status: "failed",
          msg: "ยกเลิกการลงทะเบียนใช้บริการ SKL Connect ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        };
      }
    }
  }
};
</script>

<style scoped>
.main-container {
 max-width: 340px !important;
}
.body-h {
  height: calc(100vh - 150px) !important;
}
.custom-btn {
  width: 100% !important;
  margin: 0 !important;
}
</style>
