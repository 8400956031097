<template>
  <div>
    <div class="justify-content-end d-flex f-14">
      <p class="f-medium">เลขที่สัญญา : {{ profile.contractNo }}</p>
   
    </div>
    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      :busy="isBusy"
      show-empty
      empty-text="ไม่พบข้อมูล"
      class="greenColor mt-sm-3 mt-2"
    >
      <template v-slot:cell(inst_Tot_Amt)="data">
        <p class="mb-0 text-right">
          {{
            Number(data.item.inst_Tot_Amt)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
          }}
        </p>
      </template>
      <template v-slot:cell(act_Tot_Amt)="data">
        <p class="mb-0 text-right">
          {{
            Number(data.item.act_Tot_Amt)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
          }}
          บาท
        </p></template
      >
    </b-table>
    <p class="mb-0 f-12 f-orange text-end">
      ข้อมูล ณ วันที่ {{ profile.asOfDate || "-" }} <br />
    </p>
    <div class="display-inline w-100">
      <span class="txt-note"
        >หมายเหตุ : ข้อมูลที่แสดงในตารางเป็นข้อมูลการชำระย้อนหลัง 3 เดือน</span
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    lineProfile: {
      required: false,
      type: Object
    },
    contractNo: {
      required: false,
      type: String
    }
  },
  data: () => {
    return {
      lineId: "",
      profile: {},
      isBusy: false,
      items: [],
      fields: [
        {
          key: "tran_Date",
          label: "วันที่ชำระ",
          class: "text-center align-middle"
        },
        {
          key: "hpi_Rec_Amt_String",
          label: "จำนวนเงินตามใบเสร็จ (บาท)",
          thClass:"text-center",
          tdClass: "text-end align-middle"
        },
        {
          key: "hpi_Rec_Runno",
          label: "เลขที่ใบเสร็จรับเงิน",
          class: "text-center align-middle"
        }
      ]
    };
  },
  created() {
    // this.getPaymentHistoryList();
  },
  methods: {
    async getPaymentHistoryList() {
      // this.$isLoadingAlpha = true;
      this.isBusy = true;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        LineId: this.lineProfile.userId,
        ContractNo: this.contractNo
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(
        `${this.$API_DEV_LINE_SKL}/contract/paymenthistory`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.items = result.detail.detail;
            this.profile = result.detail;
            this.isBusy = false;
            this.$isLoadingAlpha = false;
          } else {
            document.getElementById(
              "content-payment"
            ).innerHTML = `<div class="content_center body f-14">${result.message}</div>`;
            this.$isLoadingAlpha = false;
          }
        })
        .catch(error => {
          this.isBusy = false;
          this.items = [];
          console.error(error);
        });
      this.$isLoadingAlpha = false;
    }
  }
};
</script>

<style scoped>
::v-deep table > thead > tr > th {
  border: 0.5px solid #cdfca1;
}
::v-deep table > tbody > tr > td {
  border-left: 0.5px solid #cdfca1;
  border-right: 0.5px solid #cdfca1;
}
::v-deep tr:last-child {
  border-bottom: 0.5px solid #cdfca1;
}
@media (max-width: 767.98px) {
  .title-note {
    width: 30%;
  }
  .content-note {
    width: 70%;
  }
  .header-table,
  ::v-deep .table td {
    font-size: 12px;
  }
  ::v-deep .table th {
    padding: 0.5rem;
    font-size: 12px !important;
  }
  /* p {
    font-size: 8px;
  }
  span {
    font-size: 8px;
  } */
}
.txt-note {
  font-size: 10px;
}
.display-inline {
  display: inline-flex;
}
</style>
