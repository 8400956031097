<template>
  <div :class="['form-group d-flex']">

    <b-input-group
      v-for="(item, index) in otp"
      :key="index"
      class="input-group-prepend-custom p-1"
    >
      <input
        :ref="'otpInput' + index"
        :class="['form-control', 'input-text', 'color-placeholder']"
        type="number"
        v-model="otp[index]"
        @input="handleInput(index, otp[index], $event)"
        @keyup="handleKeyUp(index, $event)"
        @keydown="handleKeyDown(index, $event)"
        maxlength="1"
      />
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: "input-textbox",
  props: {},
  data() {
    return {
      otp: ["", "", "", "", "", ""]
    };
  },
  watch: {
    otp: {
      handler() {
        const otpValue = this.otp.map(item => item).join("");
        console.log(otpValue);
        this.$emit("input", otpValue);
      },
      deep: true
    }
  },
  methods: {
    handleInput(index, value, event) {
      console.log(value);
      if (value.length > 1) {
        if (value.length == 6) {
          for (let i = 0; i < this.otp.length; i++) {
            this.otp[i] = value[i] || "";
          }
        } else this.otp[index] = value.slice(-1);
      }
    
    },
    handleKeyDown(index, event) {
      // เมื่อกดตัวเลขให้ focus ช่องถัดไป
      if (
        event.key >= "0" &&
        event.key <= "9" &&
        index < this.otp.length - 1 &&
        this.otp[index]
      ) {
 

        this.$refs["otpInput" + (index + 1)][0].focus();
      }
    },
    handleKeyUp(index, event) {
      if (event.key === "Backspace" && index > 0 && !this.otp[index]) {
        this.$refs["otpInput" + (index - 1)][0].focus();
      }
    }
  }
};
</script>

<style scoped>
input[type="number"] {
  -webkit-text-security: disc;
}
.input-group-prepend-custom .input-group-prepend .input-group-text {
  background-color: white !important;
  border-radius: 8px 0px 0px 8px !important;
  color: #afafaf !important;
  border-right: 0 !important;
  display: none;
  align-items: center !important;
  padding-right: 0 !important;
}
.input-group-prepend-custom.error-input .input-group-prepend .input-group-text {
  border-color: red !important;
}
.input-group-prepend-custom.display .input-group-prepend .input-group-text {
  display: flex !important;
}
.input-text {
  border-radius: 8px !important;
  text-align: center;
  width: 50px !important;
}
.color-placeholder::placeholder {
  color: #f47306 !important;
  opacity: 1; /* Firefox */
}
</style>
