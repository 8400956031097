<template>
  <div>
    <div class="justify-content-between d-flex px-4 f-14">
      <p>เลขที่สัญญา :</p>
      <p class="f-medium">{{ displayContract }}</p>
    </div>
    <div v-if="items.length == 0" class="text-center mb-5 mt-5 f-orange f-14">
      ยังไม่พบเอกสารใบแจ้งชำระเงินของท่านในรอบบิลนี้
    </div>
    <div v-else>
      <b-card v-for="(item, index) in items" :key="index" class="p-3 mt-2">
        <div class="f-14 d-flex justify-content-between">
          <div>ใบแจ้งชำระเงิน รอบวันที่ {{ item.paymentDate }}</div>
          <font-awesome-icon
            class="icon-w f-orange"
            :icon="['fas', 'download']"
            @click="downLoadInvoice(item)"
          />
        </div>
      </b-card>
    </div>
 
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    lineProfile: {
      required: false,
      type: Object
    },
    contractNo: {
      required: false,
      type: String
    },
    displayContract: {
      required: false,
      type: String
    }
  },
  data: () => {
    return {
      isBusy: false,
      items: [],
      profile: {}
    };
  },
  created() {},
  methods: {
    async getInvoiceList() {
      // this.$isLoadingAlpha = true;
      this.isBusy = true;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        LineId: this.lineProfile.userId,
        ContractNo: this.contractNo
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw
      };

      await fetch(
        `${this.$API_DEV_LINE_SKL}/contract/paymentInvoice`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.items = result.detail.detail;
            this.profile.contractNo = result.detail.contractNo;
            this.isBusy = false;
          }
        })
        .catch(error => {
          this.isBusy = false;
          this.items = [];
          console.error(error);
        });
      this.$isLoadingAlpha = false;
    },
    downLoadInvoice(item) {
      const fileUrl = item.filePath;

      const link = document.createElement("a");
      link.href = fileUrl;

      const paymentDate = item.paymentDate.replace(/\//g, "-");
      link.download = `Invoice_${paymentDate}.pdf`;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }
  }
};
</script>

<style scoped>
.txt-note {
  font-size: 12px;
}
.display-inline {
  display: inline-flex;
}

::v-deep .card-body {
  padding: 0 !important;
}

.icon-w {
  width: 20px;
  height: 20px;
}
</style>
