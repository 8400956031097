<template>
  <b-modal id="modal-success" hide-footer hide-header>
    <p class="my-2 text-center">การชำระเงินสำเร็จ</p>
  </b-modal>
</template>

<script>
export default {};
</script>

<style></style>
