<template>
  <div>
    <p class="f-medium">สัญญาสินเชื่อของท่าน</p>
    <div class="">
      <div class="justify-content-between d-flex f-14">
        <p class="mt-1">เลขที่สัญญา :</p>
        <p class="f-medium">{{ contract.contractNoDisplay }}</p>
      </div>
      <div
        class="f-14"
        v-if="contract.contractType !== 'PL' && contract.contractType !== 'TL'"
      >
        <p class="f-medium">ประเภทการชำระเงิน :</p>

        <InputSelect
          valueField="value"
          textField="name"
          :options="paymentOptions"
          v-model="selectedPayment"
          @onDataChange="handlePaymentChange"
        >
          <template v-slot:option-first>
            <b-form-select-option value="" disabled>
              -- เลือกประเภทการชำระ --
            </b-form-select-option>
          </template></InputSelect
        >
      </div>

      <p class="f-14 f-medium">รายละเอียดการชำระเงิน :</p>

      <div class="justify-content-between d-flex f-14">
        <p class="">เลขที่อ้างอิง 1 (ref.1) :</p>
        <p class="f-medium">{{ numberRef }}</p>
      </div>

      <div class="justify-content-between d-flex f-14">
        <p class="text-nowrap">ยอดเงินที่ต้องชำระ :</p>
        <p
          class="f-medium text-end"
          :class="{
            'f-orange': ['SP', 'L'].includes(contract.collectionStatusUnits)
          }"
        >
          {{
            !["SP", "L"].includes(contract.collectionStatusUnits)
              ? totalAmount
              : "ตามที่ตกลงกับเจ้าหน้าที่"
          }}
        </p>
      </div>

      <div class="mt-2 d-flex alihn-items-center text-center">
        <div :class="['mr-2 mt-2', { 'mb-4': $v.form.total.$error }]">
          <svg
            class="svg-check-box"
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            @click="form.isEdit = !form.isEdit"
          >
            <g
              id="Rectangle_1784"
              data-name="Rectangle 1784"
              fill="#fff"
              stroke="#707070"
              stroke-width="1"
            >
              <rect width="18" height="18" rx="3" stroke="none" />
              <rect
                x="0.5"
                y="0.5"
                width="18"
                height="18"
                rx="2.5"
                fill="none"
              />
            </g>
            <path
              d="M5 10 L8 13 L15 5"
              fill="none"
              stroke="#80c141"
              stroke-linecap="round"
              stroke-width="2"
              v-if="form.isEdit"
            />
          </svg>
        </div>
        <div class="flex-grow-1">
          <InputTextBox
            class="custom-text-box"
            id="input-1"
            v-model="form.totalFormatted"
            :placeholder="
              !['SP', 'L'].includes(contract.collectionStatusUnits)
                ? 'แก้ไขยอดที่ต้องการชำระ'
                : 'แก้ไขยอดที่ต้องการชำระ'
            "
            type="text"
            :isError="form.isError"
            :isDisabled="!form.isEdit"
            inputChecker="price"
            @input="formatCurrency($event)"
          />

          <div
            id="input-error"
            class="mt-1 text-center"
            v-if="$v.form.total.$error"
          >
            <font color="red">ยอดเงินชำระขั้นต่ำ 100 บาท</font>
          </div>
        </div>
        <!-- :changeColorPlaceholder=" ['SP',
            'L'].includes(customerProfile.CollectionStatusUnits) " -->
      </div>

      <div class="payment mt-2">
        <p class="mb-0 f-medium">เลือกธนาคารที่ท่านต้องการใช้บริการ</p>
        <p class="f-small-gray">
          กรุณาเข้าสู่ระบบโมบายแบงก์กิ้งของท่าน เพื่อดำเนินการชำระเงินให้สมบรูณ์
        </p>
        <div class="from-detail">
          <div class="detail-item">
            <b-form-group>
              <b-form-radio-group
                v-model="form.bankId"
                v-for="(option, index) in options"
                v-bind:key="index"
                size="sm"
                stacked
                plain
                class="mt-2 f-14"
              >
                <b-form-radio :value="index" class="d-flex f-14 mt-1">
                  <img :src="option.imageUrl" class="icon-bank mt-1 ml-1" />
                  {{ option.name }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <div id="input-error" class="text-center mb-2" v-if="$v.form.bankId.$error">
      <font color="red">กรุณาเลือกธนาคาร</font>
    </div>

    <div class="text-center">
      <b-button
        id="submit-payment"
        class="w-100 btn-green"
        type="button"
        @click="submitForm"
        :disabled="buttonLoading"
      >
        <b-spinner small v-if="buttonLoading"></b-spinner>
        <span v-else>ชำระเงิน</span>
      </b-button>
    </div>
    <div
      v-if="(state = 'payPage')"
      :style="{
        position: 'absolute',
        top: '-400px',
        'z-index': '-10',
        opacity: 0
      }"
    >
      <form :action="pay_onlineResponse.PostUrl" method="post">
        <input
          type="text"
          name="Customer"
          :value="pay_onlineResponse.Customer"
        />
        <input type="text" name="Ref1" :value="pay_onlineResponse.Ref1" />
        <input
          type="text"
          name="ContacNo"
          :value="pay_onlineResponse.ContacNo"
        />
        <input
          type="text"
          name="TranSactionNo"
          :value="pay_onlineResponse.TranSactionNo"
        />
        <input type="text" name="Amount" :value="pay_onlineResponse.Amount" />
        <input
          type="text"
          name="ProductInfo"
          :value="pay_onlineResponse.ProductInfo"
        />
        <input
          type="text"
          name="TranSactionDate"
          :value="pay_onlineResponse.TranSactionDate"
        />
        <input
          type="text"
          name="BankGate"
          :value="pay_onlineResponse.BankGate"
        />
        <input type="text" name="ChannelID" value="2" />
        <button ref="submit">submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import InputSelect from "../../../components/inputs/InputSelect.vue";
const InputTextBox = () => import("@/components/inputs/InputTextBox-New");
import {
  required,
  requiredIf,
  decimal,
  helpers
} from "vuelidate/lib/validators";

const greaterThan100 = helpers.withParams(
  { type: "greaterThan100" },
  (value, vm) => {
    if (vm.isEdit) {
      const numericValue = parseFloat(value);
      return !isNaN(numericValue) && numericValue >= 100;
    }
    return true;
  }
);

export default {
  components: { InputSelect, InputTextBox },
  props: {
    idCard: { required: false, type: String },
    lineProfile: {
      required: false,
      type: Object
    },
    contract: {
      required: false,
      type: Object
    }
    // displayContractNo:{
    //   required: true,
    //   type: String
    // }
  },

  validations() {
    return {
      form: {
        total: {
          required: requiredIf(() => this.form.isEdit),
          greaterThan100
        },
        bankId: { required, decimal }
      }
    };
  },
  data: () => {
    return {
      lineId: "",

      callCenter: "",
      form: {
        bankId: null,
        totalFormatted: "",
        total: "",
        isEdit: false,
        isError: false
      },
      paymentOptions: [],
      selectedPayment: "",
      state: "form",
      payPageHTML: "",
      pay_onlineResponse: {},
      numberRef: "",
      totalAmount: 0.0,

      barcodeUrl: {},
      options: [],
      buttonLoading: false,
      otherOptions: [
        { src: "counter.png", alt: "" },
        { src: "lotus.png", alt: "" },
        { src: "thaipost.png", alt: "" },
        { src: "mpay.png", alt: "" }
      ],
      contractNo: ""
    };
  },
  created() {},
  methods: {
    async getOnlinePayment() {
      this.form = {
        bankId: null,
        total: "",
        isEdit: false,
        isError: false
      };

      var myHeadersBankList = new Headers();
      myHeadersBankList.append("API-KEY", "S1112LIN05!");

      var requestOptionsBankList = {
        method: "GET",
        headers: myHeadersBankList,
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/Bank/OnlinePayment`,
        requestOptionsBankList
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.options = result.detail;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    async getPaymentAmount(contract) {
      var myHeaders = new Headers();
      this.getOnlinePayment();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      var raw = JSON.stringify({
        ContractNo: contract,
        LineId: this.lineProfile.userId,
        trancode: this.selectedPayment
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/payment/Amount`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.totalAmount = result.detail.amount;
            this.paymentOptions = result.detail.paymentOptions;
            this.selectedPayment = result.detail.tranCode;
            this.getContactBarcodeImg(contract);
          } else {
            this.totalAmount = "0.00";
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    changeNumberRef(numberRef) {
      let indexContractNo = numberRef.indexOf(this.contract.contractNo);
      let lengthContractNo = this.contract.contractNo.length;
      this.numberRef = numberRef.substring(
        0,
        indexContractNo + lengthContractNo
      );
    },
    async getContactBarcodeImg(contractNo, contractType) {
      // this.$isLoadingAlpha = true;
      this.contractNo = contractNo;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);

      var raw = JSON.stringify({
        ContractNo: contractNo,
        LineId: this.lineProfile.userId,
        trancode: this.selectedPayment
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(
        `${this.$API_DEV_LINE_SKL}/payment/QRCodeByTranCode`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.numberRef = result.detail.ref1;
          }
        })
        .catch(error => {
          console.error(error);
        });
      this.$isLoadingAlpha = false;
    },
    formatCurrency(event) {
      let value = event.replace(/[^0-9.]/g, "");

      if (value === "") {
        this.form.total = "";
        this.form.totalFormatted = "";
        return;
      }

      const formattedValue = Number(value).toLocaleString("en-US", {
        maximumFractionDigits: 2
      });

      this.form.total = value;
      this.form.totalFormatted = formattedValue;
    },
    async submitForm() {
      this.$v.form.$touch();

      if (this.$v.form.bankId.$error) {
        this.$bvModal.show("bankAlert");

        return;
      } else if (this.$v.form.$error) {
        return;
      }

      // this.$isLoadingAlpha = true;
      this.form.isError = false;
      var userPay;

      if (this.form.isEdit) {
        if (parseFloat(this.form.total) < 100) {
          this.form.isError = true;
          return;
        } else {
          userPay = this.form.total.toString();
        }
      } else {
        userPay = this.totalAmount;
      }

      // this.buttonLoading = true;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        Authorization: this.$NIMBUS_TOKEN,
        RequestBody: JSON.stringify({
          ContractNo: this.contractNo,
          PaymentBank: this.options[this.form.bankId].code,
          PaymentAmount:
            parseFloat(userPay.replace(/,/g, "")).toString() ||
            this.totalAmount.replace(/,/g, "").toString(),
          IdCard: this.idCard,
          ChannelID: 2
        }),
        Path: `${this.$API_DEV_SKL}/payment_online`,
        Method: "POST",
        type: this.type,
        LineId: this.lineProfile.userId,
        Ref1: this.numberRef
      });

      console.log(
        "RequestBody PortalPaymentOnline: " +
          JSON.stringify({
            ContractNo: this.contractNo,
            PaymentBank: this.options[this.form.bankId].code,
            PaymentAmount: parseFloat(userPay.replace(/,/g, "")).toString(),
            IdCard: this.idCard,
            ChannelID: 2
          })
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(
        `${this.$API_DEV_LINE_SKL}/PortalPaymentOnline`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          this.buttonLoading = false;
          if (result.result) {
            // this.$bvModal.show("modal-success");
            this.pay_onlineResponse = result.data;
            console.log(
              "result PortalPaymentOnline" + JSON.stringify(result.data)
            );
            this.state = "payPage";
            this.$nextTick(() => {
              this.$refs.submit.click();
              this.form.bankId = null;
            });
          } else if (result.message !== "ไม่สำเร็จ") {
            this.pay_onlineResponse = result.data;
            console.log(
              "result PortalPaymentOnline" + JSON.stringify(result.data)
            );
            this.state = "payPage";
            this.$nextTick(() => {
              this.$refs.submit.click();
              this.form.bankId = null;
            });
          } else {
            this.$bvModal.show("modal-tryagain");
          }
        })
        .catch(error => {
          this.buttonLoading = false;
          this.$bvModal.show("modal-tryagain");
          console.error(error);
        });
      this.$isLoadingAlpha = false;
    },
    handlePaymentChange(val) {
      this.selectedPayment = val;
      this.getPaymentAmount(this.contractNo);
    },
    goPaymentPage() {
      console.log("goPaymentPage");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        Authorization: this.$NIMBUS_TOKEN,
        RequestBody: JSON.stringify({
          Customer: this.pay_onlineResponse.Customer,
          Ref1: this.pay_onlineResponse.Ref1,
          ContacNo: this.pay_onlineResponse.ContacNo,
          TranSactionNo: this.pay_onlineResponse.TranSactionNo,
          Amount: this.pay_onlineResponse.Amount,
          ProductInfo: this.pay_onlineResponse.ProductInfo,
          TranSactionDate: this.pay_onlineResponse.TranSactionDate,
          BankGate: this.pay_onlineResponse.BankGate,
          ChannelID: this.pay_onlineResponse.ChannelID,
          CHECKVAL: this.pay_onlineResponse.CHECKVAL
        }),
        Path: this.pay_onlineResponse.PostUrl,
        Method: "POST"
      });

      console.log("path field PostUrl: " + this.pay_onlineResponse.PostUrl);
      console.log(
        "RequestBody Portal: " +
          JSON.stringify({
            Customer: this.pay_onlineResponse.Customer,
            Ref1: this.pay_onlineResponse.Ref1,
            ContacNo: this.pay_onlineResponse.ContacNo,
            TranSactionNo: this.pay_onlineResponse.TranSactionNo,
            Amount: this.pay_onlineResponse.Amount,
            ProductInfo: this.pay_onlineResponse.ProductInfo,
            TranSactionDate: this.pay_onlineResponse.TranSactionDate,
            BankGate: this.pay_onlineResponse.BankGate,
            ChannelID: this.pay_onlineResponse.ChannelID,
            CHECKVAL: this.pay_onlineResponse.CHECKVAL
          })
      );
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/Portal`, requestOptions)
        .then(response => response.text())
        .then(result => {
          this.payPageHTML = result;
          this.$nextTick(() => {
            this.state = "payPage";
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style scoped>
.barcode-size {
  width: 100px;
  height: 100px;
}

.f-10 {
  font-size: 10px !important;
}

.bg-gray {
  background-color: rgb(231, 231, 231);
}

.icon-bank {
  height: auto;
  width: 30px;

  background-image: none !important;
}

.icon-pay {
  height: 40px;
}

::v-deep input[type="radio"] {
  margin-top: 10px !important;
}

.custom-text-box ::v-deep input {
  font-size: 12px !important;
  margin-top: 6px;
  height: 32px !important;
}

::v-deep .form-check-input {
  width: 18px;
  height: 18px;
}
</style>
