5
<template>
  <div class="px-2">
    <div class="justify-content-between d-flex f-14">
      <p>เลขที่สัญญา :</p>
      <p class="f-14 f-medium">{{ displayContract }}</p>
    </div>
    <div v-if="contract.collectionStatusUnits == 'C'" class="h-100">
      <hr />
      <diV class="text-center f-orange f-14 py-4">
        สัญญาสินเชื่อของท่านได้ปิดบัญชีเรียบร้อยแล้ว
      </diV>
    </div>
    <div
      v-else-if="
        contract.collectionStatusUnits == 'L' ||
        contract.collectionStatusUnits == 'SP'
      "
      class="h-100"
    >
      <hr />
      <diV class="text-center f-orange f-14 my-5">
        จำนวนที่ท่านต้องชำระขึ้นอยู่กับจำนวนเงินตามที่ระบุในสัญญาสินเชื่อของท่านหรือเป็นไปตามที่ท่านได้ตกลงกับเจ้าหน้าที่ไว้
      </diV>
    </div>
    <div
      v-else-if="
        contract.contractType == 'HP' ||
        contract.contractType == 'FL' ||
        contract.contractType == 'PL' ||
        contract.contractType == 'TL'
      "
    >
      <b-row class="mt-2 0">
        <b-col cols="12" class="px-1">
          <div class="card-custom d-flex justify-content-between px-1 py-1">
            <div class="">
              <p class="f-14">
                ค่างวดคงเหลือทั้งสัญญา <span class="f-12">(บาท)</span>
              </p>
              <p class="f-12 f-gray">
                กำหนดชำระงวดสุดท้าย {{ contractInfo.endDueDateDisplay }}
              </p>
            </div>
            <p class="f-medium f-14 d-flex mt-4">
              {{ contractInfo.totalOutstandingBalance }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" class="mt-2 px-1">
          <div class="card-custom d-flex justify-content-between px-1 py-1">
            <div
              class=""
              v-if="
                contract.contractType === 'HP' || contract.contractType === 'FL'
              "
            >
              <p class="f-14 text-nowrap">
                ค่างวดค้างชำระ <span class="f-12">(บาท)</span>
              </p>
              <p class="f-12 f-gray">
                ค้างชำระตั้งแต่ {{ contractInfo.overdueDueDateDisplay }} จำนวน
                {{ contractInfo.overduePeriod }} งวด
              </p>
            </div>
            <div
              class=""
              v-else-if="
                contract.contractType === 'PL' || contract.contractType === 'TL'
              "
            >
              <p class="f-14 text-nowrap">
                ค่างวดที่ต้องชำระ <span class="f-12">(บาท)</span>
              </p>
              <p class="f-12 f-gray">
                วันที่ครบกำหนด {{ contractInfo.dueDateDisplay }}
              </p>
            </div>
            <p class="f-medium f-14 f-orange d-flex mt-4">
              {{
                contract.contractType === "HP" || contract.contractType === "FL"
                  ? contractInfo.overdueAmount
                  : contractInfo.installmentAmount
              }}
            </p>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="px-1"
          v-if="
            contract.contractType === 'HP' || contract.contractType === 'FL'
          "
        >
          <div
            class="d-flex justify-content-between mt-2 px-1 py-1 card-custom"
          >
            <div class="">
              <p class="f-14 text-nowrap">
                ค่างวดถัดไป <span class="f-12">(บาท)</span>
              </p>
              <p class="f-12 f-gray">
                กำหนดชำระในวันที่ {{ contractInfo.nextPaymentDueDateDisplay }}
              </p>
            </div>
            <p class="f-medium f-14 f-orange d-flex mt-4">
              {{ contractInfo.nextPaymentAmount }}
            </p>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="mt-2 px-1 pr-0"
          v-if="contractInfo.displayPenalty"
        >
          <div class="card-custom p-1">
            <div class="">
              <p class="f-14 text-nowrap">
                ค่าเบี้ยปรับ <span class="f-12">(บาท)</span>
              </p>
            </div>
            <p class="f-medium f-14 f-orange text-end">
              {{ contractInfo.penaltyAmt }}
            </p>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="mt-2 px-1 pl-0"
          v-if="contractInfo.displayCollective"
        >
          <div class="card-custom p-1">
            <div class="">
              <p class="f-14 text-nowrap">
                ค่าติดตามทวงถาม <span class="f-12">(บาท)</span>
              </p>
            </div>
            <p class="f-medium f-14 f-orange text-end">
              {{ contractInfo.callCharge }}
            </p>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="px-1"
          v-if="
            contract.contractType === 'PL' || contract.contractType === 'TL'
          "
        >
          <div
            class="d-flex justify-content-between mt-2 px-1 py-1 card-custom bg-light-green"
          >
            <div class="">
              <p class="f-14 f-medium">
                รวมเงินที่ต้องชำระทั้งสิ้น <span class="">(บาท)</span>
              </p>
            </div>
            <p class="f-medium f-14 d-flex mt-3 f-orange">
              {{ contractInfo.totalNextPaymentAmount }}
            </p>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="mt-2 px-1 pr-0"
          v-if="
            (contract.contractType === 'HP' ||
              contract.contractType === 'FL') &&
            contractInfo.displayTax
          "
        >
          <div class="card-custom p-1">
            <div class="">
              <p class="f-14 text-nowrap">
                ค่าภาษี <span class="f-12">(บาท)</span>
              </p>
            </div>
            <p class="f-medium f-14 f-orange text-end">
              {{ contractInfo.taxPaymentAmount }}
            </p>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="mt-2 px-1 pl-0"
          v-if="
            (contract.contractType === 'HP' ||
              contract.contractType === 'FL') &&
            contractInfo.displayRI
          "
        >
          <div class="card-custom p-1">
            <div class="">
              <p class="f-14 text-nowrap">
                ค่าบริการ พ.ร.บ. <span class="f-12">(บาท)</span>
              </p>
            </div>
            <p class="f-medium f-14 f-orange text-end">
              {{ contractInfo.riPaymentAmount }}
            </p>
          </div>
        </b-col>
      </b-row>
      <p class="f-12 text-center mt-2">
        *ยอดชำระค่างวดจะอัปเดตหลังจากวันชำระเงิน 2 วันทำการ
      </p>
      <!-- <p class="f-orange text-end f-12 mt-2">ข้อมูล ณ วันที่ {{ toDay }}</p> -->
    </div>

    <div
      v-if="
        contract.collectionStatusUnits !== 'L' &&
        contract.collectionStatusUnits !== 'SP'
      "
    >
      <p class="mb-0 txt-note f-orange text-end">
        ข้อมูล ณ วันที่ {{ contractInfo.asOfDateDisplay || "-" }} <br />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    lineProfile: {
      required: false,
      type: Object
    },
    contract: {
      required: false,
      type: Object
    },
    displayContract: {
      required: false,
      type: String
    }
  },
  computed: {
    calculatedAmount() {
      if (
        this.contract.contractType === "PL" ||
        this.contract.contractType === "TL"
      ) {
        const overdueAmount = Number();
        // this.contractInfo.overdueAmount.replace(/,/g, "")
        const nextPaymentAmount = Number();
        // this.contractInfo.nextPaymentAmount.replace(/,/g, "")
        return (overdueAmount + nextPaymentAmount).toFixed(2);
      } else {
        return this.contractInfo.overdueAmount;
      }
    }
  },
  data: () => {
    return {
      callCenter: "",
      contractInfo: {},
      toDay: "",

    };
  },
  created() {
    // this.getContratctInstallmentDetail();
    this.toDay = this.formatToThaiDate(new Date());
  },
  methods: {
    formatToThaiDate(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day.toString().padStart(2, "0")}/${month
        .toString()
        .padStart(2, "0")}/${year}`;
    },
    async getContratctInstallmentDetail(contarctNo) {
      // this.$isLoadingAlpha = true;
      var raw = JSON.stringify({
        ContractNo: contarctNo,
        LineId: this.lineProfile.userId
      });

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      await fetch(
        `${this.$API_DEV_LINE_SKL}/contract/installment`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.contractInfo = result.detail;
          }
        })
        .catch(error => {
          console.error(error);
        });
      this.$isLoadingAlpha = false;
    },
    formatNumber(value) {
      return Number(value || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
  }
};
</script>

<style scoped>
.card-custom {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  min-height: 52px !important;
}
.txt-note {
  font-size: 12px;
}
.display-inline {
  display: inline-flex;
}

.bg-light-green {
  background-color: #b7e28b;
}
</style>
