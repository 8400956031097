<template>
  <div>
    <div id="header">
      <div class="d-flex flex-row justify-content-around logo">
        <img
          src="../assets/images/SKL/skl-logo.jpg"
          alt="Smiley face"
          width="150"
        />
      </div>
      <div id="header-title" class="text-center title">
        <b>{{ feedBackForm.setting.header }}</b>
      </div>
    </div>
    <div class="body" v-if="showContent">
      <div v-if="page == 1">
        <b-row class="mt-2 mb-0" v-if="satisfiedStatus">
          <b-col>
            <b-form-group>
              <label for="1">
                {{ feedBackForm.setting.title }}
                <font color="red">*</font>
              </label>
              <div class=" d-flex justify-content-around ">
                <div
                  v-for="item in feedBackForm.setting.feeling"
                  v-bind:key="item.id"
                  class="panel-icon-feeling mx-1 text-center"
                  @click="onSelectFeeling(item)"
                >
                  <div class="icon-feeling mb-1 mx-auto">
                    <div
                      v-show="item.id != selectFeeling.id"
                      :class="[
                        'icon-img rounded-circle icon-normal',
                        {
                          'border border-danger': selectFeeling.isCheckNotSelect
                        }
                      ]"
                      v-bind:style="{
                        backgroundImage: 'url(' + item.normalImage + ')'
                      }"
                    ></div>
                    <div
                      v-show="item.id == selectFeeling.id"
                      class="icon-img rounded-circle icon-select shadow"
                      v-bind:style="{
                        backgroundImage: 'url(' + item.selectImage + ')'
                      }"
                    ></div>
                  </div>
                  <div class="panel-feeling-name">
                    <p class="feeling-name">{{ item.name }}</p>
                  </div>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <InputTextArea
              :textFloat="feedBackForm.setting.detail"
              placeholder=""
              name="addressDetails"
              rows="3"
              v-model="feedBackForm.customer.detail"
              @onDataChange="val => (feedBackForm.customer.detail = val)"
            />
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <div class="form-inline mt-3 mb-4">
              <p class="f-16 mb-0">แนบเอกสาร</p>

              <span class="f-small-gray ml-1"
                >(อัพโหลดได้ไม่เกิน 5 ไฟล์ ขนาดไม่เกิน 10 MB)</span
              >
            </div>
            <InputUploadFile
              format="file"
              v-on:onFileChange="handleChangFile"
              :isMultiple="true"
              @removeFile="removeFile()"
            />
            <template v-for="(file, index) in form.files">
              <div
                :key="file.name"
                :style="{
                  width: '100%',
                  display: 'flex',
                  'font-size': '15px',
                  'justify-content': 'space-between'
                }"
              >
                {{ file.name }}
                <img
                  src="@/assets/images/cancel.png"
                  height="20"
                  class="img-cancel"
                  @click="removeFile(index)"
                />
              </div>
            </template>
            <template v-if="inputError.maxFile">
              <div :style="{ width: '100%', 'text-align': 'center' }">
                <font color="red"
                  >อัพโหลดได้ไม่เกิน 5 ไฟล์ ขนาดไม่เกิน 10 MB</font
                >
              </div>
            </template>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <span class="mb-1">
            ข้อมูลส่วนตัว
            <span class="text-danger"> *</span>
          </span>
          <!-- <b-col class="col-12">
            <RadioButtons
              textFloat="ข้อมูลส่วนตัว"
              v-model="prefixNameGetterSetter"
              :options="prefixOptions"
              :isRequired="true"
              classFormGroup="mb-1"
              @onDataChange="onSelectPrefixName"
              :isError="$v.feedBackForm.customer.prefixName.$error"
            />
          </b-col> -->
          <b-col class="col-12">
            <InputTextBox
              id="toggle-input-name"
              v-model="feedBackForm.customer.firstName"
              placeholder="กรุณาระบุชื่อจริง"
              type="text"
              required
              classFormGroup="mb-1"
              :isError="$v.feedBackForm.customer.firstName.$error"
            />
          </b-col>
          <b-col class="col-12">
            <InputTextBox
              id="toggle-input-name"
              v-model="feedBackForm.customer.lastName"
              placeholder="กรุณาระบุนามสกุล"
              type="text"
              required
              classFormGroup="mb-1"
              :isError="$v.feedBackForm.customer.lastName.$error"
            />
          </b-col>
          <b-col class="col-12">
            <InputTextBox
              id="toggle-input-contractNumber"
              v-model="feedBackForm.customer.mobileNo"
              placeholder="กรุณาระบุเบอร์โทรศัพท์"
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              inputChecker="number"
              :maxlength="10"
              required
              :isError="$v.feedBackForm.customer.mobileNo.$error"
            />
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col class="col-12">
            <InputCheckBox
              text="ต้องการให้ติดต่อกลับ"
              v-model="feedBackForm.customer.callBack"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-form-checkbox
            v-if="consentState.isShow"
            v-model="consentState.isCheck"
            :style="{
              'font-size': '0.625rem',
              'line-height': '1.3',
              'margin-bottom': '10px'
            }"
          >
            ข้าพเจ้ารับทราบว่า ข้าพเจ้าแจ้งความประสงค์สอบถามข้อมูล ส่งคำถามถึง
            บริษัท สยามคูโบต้า ลีสซิ่ง จำกัด ผ่านช่องทางอิเล็กทรอนิกส์
            ข้าพเจ้าได้อ่าน<a
              id="redirectPrivacyPolicy"
              href="https://skl.co.th/privacy_policy"
              class="f-orange"
              ><u>นโยบายคุ้มครองส่วนบุคคล</u></a
            >
            และยอมรับ<a
              id="redirectTerms"
              href="https://www.skl.co.th/terms"
              class="f-orange"
              ><u>ข้อกำหนดและเงื่อนไขการใช้งาน</u></a
            >
            ของบริษัทฯ แล้ว และรับทราบว่าบริษัทฯ
            จะใช้หรือเปิดเผยข้อมูลข้างต้นให้แก่สยามคูโบต้าคอร์ปอเรชั่นและผู้แทนจำหน่ายสยามคูโบต้า
            ในการติดต่อข้าพเจ้าเพื่อจัดการคำขอและข้อสอบถามต่างๆตามที่ข้าพเจ้าแจ้งความประสงค์นี้
          </b-form-checkbox>
        </b-row>
        <b-row>
          <b-col class="col-12 text-center my-2">
            <b-button
              id="submit-feedBack"
              variant="submit"
              @click="submitFeedBack()"
              :disabled="
                buttonLoading || (consentState.isShow && !consentState.isCheck)
              "
              >ยืนยัน</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div v-else-if="page == 2">
        <PageResult :data="dataResult" />
      </div>
    </div>
    <PolicyModal ref="policyModal" @Accept="submitPolicy" />
  </div>
</template>

<script>
const PageResult = () => import("@/components/panels/PageResult");
const InputTextBox = () => import("@/components/inputs/InputTextBox-New");
const InputTextArea = () => import("@/components/inputs/InputTextArea");
const InputUploadFile = () => import("@/components/inputs/InputUploadFile");
// const RadioButtons = () => import("@/components/inputs/RadioButtons");
const InputCheckBox = () => import("@/components/inputs/InputCheckBox");
const PolicyModal = () => import("@/components/Policy");

import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "feedback",
  components: {
    PageResult,
    InputTextBox,
    InputTextArea,
    InputUploadFile,
    // RadioButtons,
    InputCheckBox,
    PolicyModal
  },
  data() {
    return {
      showContent: false,
      buttonLoading: false,
      lineProfile: {
        userId: "",
        // userId: "Ubae30bf2f781117833ef37831e65c38a",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      page: 1,
      dataResult: {
        status: "failed",
        msg: "ไม่สำเร็จ"
      },
      selectFeeling: {
        id: null,
        isCheckNotSelect: false
      },
      // otpState: {
      //   isError: false,
      //   otpMessage: "",
      //   waiting: false
      // },
      prefixOptions: [],
      feedBackForm: {
        customer: {
          id: 0, //
          idCard: "", //
          prefixName: "",
          customerName: "",
          firstName: "",
          lastName: "",
          mobileNo: "",
          lineID: "",
          feelingName: "",
          attachFile: false,
          detail: "",
          callBack: false,
          complaintStatusId: 0, //
          ipAddress: null, //
          isRegistered: false, //
          createdTime: "",
          updatedTime: "",
          displayName: ""
        },
        setting: {
          title: "",
          detail: "",
          header: "แนะนำติชมบริการ",
          feeling: [
            {
              id: null,
              name: "",
              sortOrder: null,
              normalImage: "",
              selectImage: ""
            }
          ],
          prefixName: [
            {
              id: null,
              name: ""
            }
          ]
        }
      },
      form: {
        files: []
      },
      inputError: {
        prefix: false,
        name: false,
        telephone: false,
        files: false,
        maxFile: false
      },
      attachFile: [],
      consentState: {
        isShow: true,
        isCheck: false
      },

      // display div
      satisfiedStatus: false,

      // text callback
      textCallBack: {
        callBack: "",
        unCallback: ""
      }
    };
  },
  computed: {
    prefixNameGetterSetter: {
      set(value) {
        this.feedBackForm.customer.prefixName = value;
      },
      get() {
        return this.feedBackForm.customer.prefixName;
      }
    }
  },
  validations() {
    return {
      feedBackForm: {
        customer: {
          prefixName: {},
          firstName: {
            required,
            maxLength: maxLength(50)
          },
          lastName: {
            required,
            maxLength: maxLength(50)
          },
          mobileNo: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10)
          }
        }
      }
    };
  },
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_FeedBack })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.getFeedbackDetail();
              this.checkTerm(profile.userId);
              // this.checkConsent();
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.getFeedbackDetail();
            this.checkTerm(profile.userId);
            // this.checkConsent();
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  mounted() {},
  methods: {
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.push({
              path: "/policy",
              query: {
                lineid: lineId,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    createConsentLinkEvent() {
      if (this.$liff.isInClient()) {
        var loop = setInterval(() => {
          if (
            document.getElementById("redirectPrivacyPolicy") &&
            document.getElementById("redirectTerms")
          ) {
            var reP = document.getElementById("redirectPrivacyPolicy");
            reP.onclick = () => {
              this.$liff.openWindow({
                url: "https://skl.co.th/privacy_policy",
                external: false
              });
              return false;
            };
            var reT = document.getElementById("redirectTerms");
            reT.onclick = () => {
              this.$liff.openWindow({
                url: "https://www.skl.co.th/terms",
                external: false
              });
              return false;
            };
            clearInterval(loop);
          }
        }, 300);
      }
    },
    checkConsent() {
      this.buttonLoading = true;
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/consent/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            // this.$refs.policyModal.content = result.detail.termsAndConditions;
            if (result.detail.consent) {
              ////
              // this.submitFeedBack();
              ////
              this.buttonLoading = false;
            } else {
              // this.$bvModal.show("modal-policy");
              // this.buttonLoading = false;
              this.consentState.isShow = true;
              setTimeout(() => {
                this.createConsentLinkEvent();
              }, 0);
              this.buttonLoading = false;
            }
          } else {
            this.$bvModal.show("modal-tryagain");
            this.buttonLoading = false;
          }
        })
        .catch(error => {
          console.log("error", error);
          this.$bvModal.show("modal-tryagain");
          this.buttonLoading = false;
        });
    },
    submitPolicy() {
      // this.buttonLoading = true;
      var requestOptions = {
        method: "POST",
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/consent/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          this.$refs.policyModal.buttonLoading = false;
          this.$bvModal.hide("modal-policy");
          if (result.result == 1) {
            ////
            // this.submitFeedBack();
            ////
          } else {
            // this.buttonLoading = false;
            this.$bvModal.show("modal-tryagain");
          }
        })
        .catch(error => {
          console.log("error", error);
          // this.$bvModal.show("modal-tryagain");
          // this.$bvModal.hide("modal-policy");
          // this.buttonLoading = false;
        });
    },
    getFeedbackDetail: async function() {
      this.$isLoadingAlpha = true;
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      await fetch(
        `${this.$API_DEV_LINE_SKL}/ComplaintReport/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(async dataResponse => {
          if (dataResponse.result == 1) {
            this.feedBackForm = dataResponse.detail;
            this.satisfiedStatus = dataResponse.detail.setting.satisfiedStatus;
            this.textCallBack = {
              callBack: dataResponse.detail.setting.callback,
              unCallback: dataResponse.detail.setting.unCallback
            };

            if (
              !this.feedBackForm.customer.firstName &&
              !this.feedBackForm.customer.lastName
            ) {
              let customerName = this.feedBackForm.customer.customerName;
              this.feedBackForm.customer.firstName = customerName
                .split(" ")
                .slice(0, -1)
                .join(" ");
              this.feedBackForm.customer.lastName = customerName
                .split(" ")
                .slice(-1)
                .join(" ");
            }
            await this.feedBackForm.setting.feeling.sort(function(a, b) {
              return a.sortOrder - b.sortOrder;
            });
            // this.prefixOptions = await this.feedBackForm.setting.prefixName.map(
            //   x => x.name
            // );
            document.title = this.feedBackForm.setting.header;
            this.$isLoadingAlpha = false;
            this.showContent = true;
          } else {
            this.$bvModal.show("modal-tryagain");
          }
        })
        .catch(error => {
          this.$isLoadingAlpha = false;
          this.$bvModal.show("modal-tryagain");
          console.error(error);
        });
    },
    onSelectFeeling(feeling) {
      this.selectFeeling.id = feeling.id;
      this.selectFeeling.isCheckNotSelect = false;
      this.feedBackForm.customer.feelingName = feeling.name;
    },
    onSelectPrefixName(val) {
      // var prefix = this.feedBackForm.setting.prefixName.filter(
      //   item => item.id == val
      // );
      // this.feedBackForm.customer.prefixName = prefix[0].name;
      // this.$v.feedBackForm.customer.prefixName.$touch();
    },
    async saveImage(imgList) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify(imgList);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      let imagelist = [];
      await fetch(`${this.$API_DEV_LINE_SKL}/attachFile`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          if (result.result == 1) {
            await result.detail.forEach(item => {
              imagelist.push(`${this.$API_DEV_LINE_SKL_IMAGE_PATH}${item}`);
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
      return imagelist;
    },
    async submitFeedBack() {
      // if (this.consentState.isShow && this.consentState.isCheck) {
      //   this.submitPolicy();
      // }
      this.$isLoadingAlpha = true;
      this.$v.feedBackForm.customer.$touch();
      if (this.$v.feedBackForm.customer.$error) {
        this.buttonLoading = false;
        this.$isLoadingAlpha = false;
        return;
      }

      this.feedBackForm.customer.lineID = this.lineProfile.userId;
      this.feedBackForm.customer.displayName = this.lineProfile.displayName;

      if (!this.selectFeeling.id && this.satisfiedStatus) {
        this.selectFeeling.isCheckNotSelect = true;
        this.buttonLoading = false;
        this.$isLoadingAlpha = false;
        return;
      }

      var data = {
        customer: this.feedBackForm.customer,
        attachFile: []
      };

      var files = this.form.files;
      for (let index = 0; index < files.length; index++) {
        await this.toBase64(files[index]).then(base64 => {
          data.attachFile.push(base64);
        });
      }

      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify(data);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      await fetch(`${this.$API_DEV_LINE_SKL}/ComplaintReport`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          this.buttonLoading = false;
          if (result.result == 1) {
            this.dataResult.status = "success";
            if (this.feedBackForm.customer.callBack) {
              this.dataResult.msg = this.textCallBack.callBack;
            } else {
              this.dataResult.msg = this.textCallBack.unCallback;
            }
            this.$isLoadingAlpha = false;
            this.page = 2;
          }
        })
        .catch(error => {
          this.dataResult.status = "failed";
          this.dataResult.msg = error;
          this.page = 2;
          this.$isLoadingAlpha = false;
          this.buttonLoading = false;
          console.error(error);
        });
    },
    handleChangFile(files) {
      this.inputError.maxFile = false;
      var tempFiles = [...this.form.files, ...files];
      console.warn("handleChangFile", files, tempFiles.length);
      if (tempFiles.length > 5) {
        console.warn("file length over 5");
        this.inputError.maxFile = true;
        return;
      }
      var sumSize = 0;
      for (let index = 0; index < tempFiles.length; index++) {
        sumSize += tempFiles[index].size;
      }
      console.warn(sumSize, sumSize / 1024 / 1024);
      if (sumSize / 1024 / 1024 > 10) {
        console.warn("sum size over 10 MB");
        this.inputError.maxFile = true;
        return;
      }
      this.form.files = [...tempFiles];
      console.warn("this.form.files", this.form.files);
    },
    removeFile(index) {
      console.warn("removeFile", index);
      if (index == undefined) {
        this.form.files = [];
        return;
      }
      this.form.files.splice(index, 1);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  }
  // created: async function() {
  //   await this.getFeedbackDetail();
  // },
};
</script>

<style scoped>
.icon-feeling {
  height: 43px;
  width: 43px;
}
.icon-img {
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.panel-feeling-name {
  width: 60px;
}

.feeling-name {
  font-size: 14px;
  margin: 0;
  overflow: show;
}

.text-file-type {
  font-size: 14px;
  color: #bcbcbc;
  float: right;
  margin-top: 2px;
}
@media (max-width: 380px) {
  .icon-feeling {
    height: 36px;
    width: 36px;
  }
}
</style>
