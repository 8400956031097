<template>
  <div class="d-flex justify-content-center custom-width">
    <div>
      <div class="main-container px-3 mt-4" ref="body">
        <div class="">
          <div class="d-flex justify-content-center">
            <img
              src="../assets/images/SKL/skl-logo.jpg"
              alt="Smiley face"
              width="150"
            />
          </div>

          <div id="header" class="font-weight-bold text-center" ref="header">
            วิธีเปลี่ยนบัญชีไลน์ในการใช้บริการ
          </div>
          <div class="d-flex justify-content-center">
            <div v-for="(item, index) in items" :key="item.index">
              <li
                @click="selectPage(index)"
                class="list__step mr-1 fs-14"
                :class="{ 'list__step-active': index == page }"
              >
                {{ index + 1 }}
              </li>
            </div>
          </div>
          <div v-for="(item, index) in items" :key="item.index">
            <p class="mt-2 f-14" v-if="page == index" v-html="item.text">
              {{ item.text }}
            </p>
            <div class="d-flex align-items-center" v-if="page == index">
              <div class="flex-grow-1 d-flex justify-content-center">
                <img
                  width="300"
                  :src="require(`../assets/images/changeLine/${item.path}.png`)"
                />
              </div>

              <div
                class="f-orange"
                v-if="page !== 3"
                @click="selectPage(index + 1)"
              >
                <b-icon icon="chevron-right" class="icon-w"></b-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    // this.$liff
    //   .init({ liffId: this.$liff_ID_Policy })
    //   .then(() => {
    //     if (!this.$liff.isLoggedIn()) {
    //       this.$liff.login().then(() => {
    //         this.$liff.getProfile().then(profile => {
    //           // this.lineProfile = profile;
    //         });
    //       });
    //     } else {
    //       this.$liff.getProfile().then(profile => {
    //         // this.lineProfile = profile;
    //       });
    //     }
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
  },
  data: () => ({
    items: [
      {
        index: 0,
        path: "step1",
        text: "กรอกข้อมูลในการลงทะเบียนใช้บริการบนบัญชีไลน์ปัจจุบัน"
      },
      {
        index: 1,
        path: "step2",
        text: `<span class="f-orange"> ระบบจะแจ้งเตือนข้อมูลการสมัครใช้บริการเดิม</span> และกดยืนยัน`
      },
      {
        index: 2,
        path: "step3",
        text: `กรอก <span class="f-orange">รหัสผ่านใช้ครั้งเดียว (OTP) จำนวน 6 ตัว</span> ที่ได้รับทางข้อความ SMS ที่หมายเลขโทรศัพท์ที่แจ้งไว้ ณ ตอนที่ทำสัญญาเช่าซื้อ และกดยืนยัน`
      },
      {
        index: 3,
        path: "step4",
        text: `<span class="f-orange">ท่านได้เปลี่ยนบัญชีไลน์เพื่อใช้บริการ SKL Connect เรียบร้อย</span> กดเริ่มต้นใช้งานเพื่อตรวจสอบข้อมูล ฯ`
      }
    ],
    page: 0
  }),

  mounted() {
    document.body.classList.add("bg-body");
  },
  methods: {
    selectPage(page) {
      this.page = page;
    }
  }
};
</script>

<style scoped>
.list__step-active {
  background: url(../assets/images/circle-green.svg) no-repeat !important;
  background-size: 100% auto;
  color: #fff !important;
}

.list__step {
  background-size: 100% auto;
  color: #888;
  cursor: pointer;
  background: url(../assets/images/circle-grey.svg) no-repeat;
  width: 53px !important;
  height: 53px !important;
  text-align: center;
  padding: 0;
  line-height: 52px;
  -webkit-transition: none;
  transition: none;
  list-style: none;
}

.icon-w {
  width: 30px;
  height: 30px;
}
</style>
