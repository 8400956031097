<template>
  <div>
    <div class="header-bg p-2">
      <b-row class="w-100 no-gutters">
        <b-col
          cols="3"
          sm="2"
          class="d-flex align-items-center justify-content-center"
        >
          <b-img :src="lineProfile.pictureUrl" class="img-header-profile" />
        </b-col>
        <b-col cols="9" sm="10" class="pl-2">
          <div class="info py-2 px-3">
            <div class="">
              <img
                src="@/assets/images/SKL/skl-connect.svg"
                alt="Smiley face"
                height="30"
                width="auto"
                class="mb-2"
              />
            </div>
            <p class="font-weight-bold">
              สวัสดี
              <span class="font-weight-normal">{{
                lineProfile.displayName
              }}</span>
            </p>
            <p class="">คุณ {{ profile.customerName }}</p>
          </div>
        </b-col>
      </b-row>
      <div class="px-3" @click="openPointPage">
        <div
          class="title-point f-14 bg-green px-2 py-1 d-flex justify-content-center f-white"
        >
          <img
            src="@/assets/images/Icon/crown.png"
            class="mr-2"
            height="18"
            width="auto"
          />
          <div class="mr-2">
            ระดับ:
            <span class="font-weight-normal">{{
              profile.loyaltyLevel || "-"
            }}</span>
          </div>
          <div>
            <img
              src="@/assets/images/Icon/SKL_Coin.png"
              class="mb-1"
              height="16"
              width="auto"
            />
            SKL พอยต์:
            <span class="font-weight-normal ">{{
              profile.sklPointString
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListHeader",
  props: {
    profile: {
      required: false,
      type: Object
    },
    lineProfile: {
      required: false,
      type: Object
    }
  },
  methods: {
    async openPointPage() {
      this.buttonLoading = true;
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      this.$isLoadingAlpha = true;
      await fetch(
        `${this.$API_DEV_LINE_SKL}/customer/loginfamilyclub/point/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            window.open(result.detail.url, "_blank");
          }

          this.buttonLoading = false;
        })
        .catch(error => console.log("error", error));
      this.$isLoadingAlpha = false;
    }
  }
};
</script>

<style scoped>
/* header */
.header-list {
  position: relative;
  margin-bottom: 3rem;
  height: 150px;
  border-radius: 20px 20px 0px 0px;
}
.bg-multi {
  /* background: linear-gradient(
        310deg,
        #7fc241 15vh,
        #dee2e6 15vh,
        #dee2e6 20vh,
        white 20vh,
        white 30vh,
        #dee2e6 30vh,
        #dee2e6 40vh,
        #f48139 40vh,
        #f48139 100vh
      )
      0 0/100% calc(100% - 30px) no-repeat,
    linear-gradient(
      310deg,
      #7fc241 20vh,
      #dee2e6 17.5vh,
      #dee2e6 23.4vh,
      white 23.4vh,
      white 33.4vh,
      #dee2e6 32.2vh,
      #dee2e6 40vh,
      #dee2e6 40vh,
      #dee2e6 100vh 
    ); */

  background: linear-gradient(
        310deg,
        #7fc241 0px,
        #7fc241 110px,
        #dee2e6 110px,
        #dee2e6 140px,
        white 140px,
        white 220px,
        #dee2e6 220px,
        #dee2e6 270px,
        #f48139 270px,
        #f48139 100%
      )
      0 0/100% calc(100% - 40px) no-repeat,
    linear-gradient(
      310deg,
      #7fc241 0px,
      #7fc241 136px,
      #dee2e6 136px,
      #dee2e6 166px,
      white 166px,
      white 246px,
      #dee2e6 246px,
      #dee2e6 296px,
      #dee2e6 296px,
      #dee2e6 100%
    );
}
.img-header-list {
  position: absolute;
  bottom: -50px;
  right: 30px;
  border: 1px black solid;
  height: 120px;
  width: 120px;
}
.name {
  padding: 3rem 0rem 0rem 3rem;
  font-weight: bold;
}
.number {
  padding: 2rem 0rem 1rem 3rem;
  font-weight: bold;
}

/* fix UI */
.img-header-profile {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.info {
  border-radius: 20px 20px 20px 20px !important;
}
.cir-icon {
  color: #f48139;
  background: white;
  padding: 1px 5px;
  margin-right: 20px;
  border-radius: 50%;
  align-self: center;
}

.header-bg {
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 2px 2px 5px -1px;
  background-image: url("../../assets/images/SKL/Profile Card.png");
  background-repeat: round;
  border-radius: 8px;
}
.line-info {
  align-items: center;
  padding: 10px 0px 10px 15px;
}
p {
  font-size: 14px;
}
span {
  font-size: 14px;
}

@media (max-width: 767.98px) {
  .img-header-list {
    bottom: -30px;
    height: 80px;
    width: 80px;
  }
  .bg-multi {
    background: linear-gradient(
          310deg,
          #7fc241 0px,
          #7fc241 50px,
          #dee2e6 50px,
          #dee2e6 70px,
          white 70px,
          white 130px,
          #dee2e6 130px,
          #dee2e6 170px,
          #f48139 170px,
          #f48139 100%
        )
        0 0/100% calc(100% - 40px) no-repeat,
      linear-gradient(
        310deg,
        #7fc241 0px,
        #7fc241 75px,
        #dee2e6 75px,
        #dee2e6 96px,
        white 96px,
        white 155px,
        #dee2e6 155px,
        #dee2e6 100%
      );
  }
  /* p {
    font-size: 8px;
  }
  span {
    font-size: 8px;
  } */
  /* fix UI */
  .img-header-profile {
    height: 80px;
    width: 80px;
    margin-left: 20px;
  }
}

::v-deep .card-body {
  padding: 0 !important;
}

.title-point {
  border-radius: 15px;
}
</style>
